import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EstatePlanningAndProbateFAQs = () => (
  <Layout>
    <SEO title="Estate Planning &#038; Probate FAQs" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">
              Estate Planning &#038; Probate FAQs
            </h1>
            <p>
              The following list answers several of the most commonly asked
              estate planning questions.
            </p>
            <h2 className="title is-4">What is a Power of Attorney?</h2>
            <h3 className="subtitle is-5">
              A Financial Power of Attorney gives an individual the right to
              make your financial decisions for you. A Healthcare Power of
              Attorney gives an individual the right to make your healthcare
              decisions for you when you are no longer able to make your own
              healthcare decisions.
            </h3>
            <div class="columns is-centered">
              <div class="column is-8">
                <div class="is-divider"></div>
              </div>
            </div>
            <h2 className="title is-4">
              What is the worst case scenario if I do not have a Power of
              Attorney?
            </h2>
            <h3 className="subtitle is-5">
              Your family would have to petition the probate court to get a
              guardianship and/or conservatorship, so that they could take care
              of your medical and/or financial situation. This costs money,
              takes time, often includes attorney’s fees, and is stressful for
              the family.
            </h3>
            <div class="columns is-centered">
              <div class="column is-8">
                <div class="is-divider"></div>
              </div>
            </div>
            <h2 className="title is-4">
              Is there a difference between a &#8220;Healthcare Power of
              Attorney&#8221;, &#8220;Appointment of Patient Advocate&#8221;,
              &#8220;Healthcare Proxy&#8221;, &#8220;Advanced Directive&#8221;,
              &#8220;Living Will&#8221;, and &#8220;5 Wishes&#8221;?
            </h2>
            <h3 className="subtitle is-5">
              People use these terms interchangeably to refer to basically the
              same thing. There are technical differences between them, but the
              average person does not need to know what these are.
            </h3>
            <div class="columns is-centered">
              <div class="column is-8">
                <div class="is-divider"></div>
              </div>
            </div>
            <h2 className="title is-4">
              Do I really need a will? What happens if I die without a will?
              Will the state get all of my money?
            </h2>
            <h3 className="subtitle is-5">
              A will gives everybody peace of mind, states exactly how you would
              like your assets to be distributed after you die, names whom you
              would like to be guardian and conservator of your minor children,
              and names who you would like to take care of your estate. If you
              die without a will, your assets will still pass to your family
              members. The state only receives a filing fee (currently $160) and
              an inventory fee based on the size of the estate.
            </h3>
            <div class="columns is-centered">
              <div class="column is-8">
                <div class="is-divider"></div>
              </div>
            </div>
            <h2 className="title is-4">
              What is probate? Why does everyone fear probate? Should I be
              worried about probate?
            </h2>
            <h3 className="subtitle is-5">
              &#8220;Probate&#8221; refers to the process of distributing your
              assets after you pass away. It is done through the county probate
              court. Your family can either hire an attorney to help with the
              process, or they can do it themselves. The probate process used to
              be very complex and expensive. Now probate is easier and less
              expensive, although there are still fees involved with filing,
              inventory, and, in most cases, attorney fees. Probate is also
              time-consuming and public record. Most people still prefer to
              avoid probate.
            </h3>
            <div class="columns is-centered">
              <div class="column is-8">
                <div class="is-divider"></div>
              </div>
            </div>
            <h2 className="title is-4">
              If I have a will, does my family still have to go through probate?
            </h2>
            <h3 className="subtitle is-5">Yes.</h3>
            <div class="columns is-centered">
              <div class="column is-8">
                <div class="is-divider"></div>
              </div>
            </div>
            <h2 className="title is-4">
              What is a &#8220;Revocable Living Trust&#8221;? Does it avoid
              probate?
            </h2>
            <h3 className="subtitle is-5">
              A Revocable Living Trust is similar to a will. It directs how you
              would like your assets to pass after death. It will avoid probate,
              but only if you properly retitle your assets into the trust. A
              Revocable Living Trust does not become public record, and
              distribution of trust assets is usually much quicker than probate.
              Most people who have dealt with trusts far prefer them to
              probating a will.
            </h3>
            <div class="columns is-centered">
              <div class="column is-8">
                <div class="is-divider"></div>
              </div>
            </div>
            <h2 className="title is-4">
              I don’t think I can afford to hire an attorney to draft these
              documents. How much does it cost? Can I just print forms off the
              internet?
            </h2>
            <h3 className="subtitle is-5">
              Wills and Powers of Attorney are usually affordable; many
              attorneys charge as little as $200 for each document. A trust is
              more expensive, usually closer to $2,000. Free internet forms
              often leave out necessary provisions.
            </h3>
            <div class="columns is-centered">
              <div class="column is-8">
                <div class="is-divider"></div>
              </div>
            </div>
            <h2 className="title is-4">
              My mother has early stage Dementia. Most of the time she knows
              what is going on, but her mind is starting to slip. Can she still
              sign a will or a power of attorney or another legal document? What
              does an attorney look for in deciding that?
            </h2>
            <h3 className="subtitle is-5">
              There is no strict procedure in place for what every attorney must
              do to determine competency or mental capacity. Generally, an
              attorney will determine if the person knows who their family
              members are, how much money or assets the person has, and whether
              the person understands what the document says and what effect it
              will have.
            </h3>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default EstatePlanningAndProbateFAQs
